import { FC } from 'react';
import { useRouter } from '../../modules/router/RouterProvider';
import Page from '../../components/pdf/Page';
import {
  customerApi,
  useCustomerGetCustomer,
  useCustomerGetDepositTag,
  useCustomerGetTokenSummary,
  useCustomerGetWithdrawalTag,
} from '../../api/customer';
import CustomerDetailsReport from '../../components/pdf/Customer/CustomerDetailsReport';
import CustomerDigitalAssetReport from '../../components/pdf/Customer/CustomerDigitalAssetReport';
import CustomerAddressesReport from '../../components/pdf/Customer/CustomerAddressesReport';
import CustomerDepositWithdrawalReport from '../../components/pdf/Customer/CustomerDepsoitWithdrawalReport';
import { useQuery } from 'react-query';
import FlowAnalysis from '../../components/pdf/Address/FlowAnalysis';
import CustomerAssociatedEntitiesReport from '../../components/pdf/Customer/CustomerAssociatedEntitiesReport';
import { useAlertGetAlerts } from '../../api/alert';
import { classifierApi } from '../../api/classifier';
import { useCommentGetComments } from '../../api/comment';
import AlertsReport from '../../components/pdf/Alerts/AlertsReport';
import ClassifiersReport from '../../components/pdf/ClassifiersReport';
import CommentsReport from '../../components/pdf/CommentsReport';
import { flattenInfiniteQueryResult } from '../../utils/helpers/react-query.helper';

const CustomerReport: FC = () => {
  const { getParams, getQueryParams } = useRouter();
  const customerId = encodeURIComponent(getParams().id || getParams().customerId);
  const entities = getQueryParams().entities?.split(',');
  const noEntity = !entities || entities.length === 0;

  const shouldShowSection = (entityName: string): boolean => {
    return noEntity || entities.includes(entityName);
  };

  const { data: customerData } = useCustomerGetCustomer(customerId);
  const { data: tokenSummary } = useCustomerGetTokenSummary(customerId, {
    enabled: shouldShowSection('addressSummary'),
  });

  const { data: depositAddresses } = useQuery(
    ['customerApi', customerId, 'getDepositAddresses'],
    () => customerApi.getDepositAddresses({ customerId }),
    {
      enabled: shouldShowSection('addressSummary'),
    }
  );

  const { data: withdrawalAddresses } = useQuery(
    ['customerApi', customerId, 'getWithdrawalAddresses'],
    () => customerApi.getWithdrawalAddresses({ customerId }),
    {
      enabled: shouldShowSection('addressSummary'),
    }
  );

  const { data: userAddresses } = useQuery(
    ['customerApi', customerId, 'getUserAddresses'],
    () => customerApi.getAnyAddress({ customerId }),
    {
      enabled: shouldShowSection('addressSummary'),
    }
  );

  const { data: depositsData } = useQuery(
    ['customerApi', customerId, 'getDeposits'],
    () => customerApi.getDeposits({ customerId }),
    {
      enabled: shouldShowSection('deposits'),
    }
  );

  const { data: withdrawalsData } = useQuery(
    ['customerApi', customerId, 'getWithdrawals'],
    () => customerApi.getWithdrawals({ customerId }),
    {
      enabled: shouldShowSection('withdrawals'),
    }
  );

  const { data: incomingValuesCustomerData } = useCustomerGetDepositTag(customerId, {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('associatedEntities'),
  });
  const { data: outgoingValuesCustomerData } = useCustomerGetWithdrawalTag(customerId, {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('associatedEntities'),
  });

  const { data: openAlertsData } = useAlertGetAlerts(
    { id: customerId, type: 'customer', status: [0, 2] },
    {
      enabled: shouldShowSection('openAlerts'),
    }
  );
  const { data: resolvedAlertsData } = useAlertGetAlerts(
    {
      id: customerId,
      type: 'customer',
      status: [1, 3],
    },
    {
      enabled: shouldShowSection('resolvedAlerts'),
    }
  );
  const { data: commentsData } = useCommentGetComments(
    { id: customerId, type: 'customer' },
    {
      enabled: shouldShowSection('customerComments'),
    }
  );
  const { data: classifiers } = useQuery(['classifierApi.getClassifiers'], () =>
    classifierApi.getClassifiers({ limit: 100, rule_type: 1 })
  );

  const [, openAlerts] = flattenInfiniteQueryResult(openAlertsData);
  const [, resolvedAlerts] = flattenInfiniteQueryResult(resolvedAlertsData);
  const [, comments] = flattenInfiniteQueryResult(commentsData);

  const isFlowAnalysisVisible = (): boolean => {
    return shouldShowSection('flowAnalysis') && !!(incomingValuesCustomerData || outgoingValuesCustomerData);
  };

  const isAssociatedEntitiesVisible = (): boolean => {
    return (
      shouldShowSection('associatedEntities') &&
      !!(
        incomingValuesCustomerData?.data?.results?.length || outgoingValuesCustomerData?.data?.results?.length
      )
    );
  };

  const isAddressSummaryVisible = (): boolean => {
    return shouldShowSection('addressSummary') && !!tokenSummary?.data?.results?.length;
  };

  const isDepositAddressesVisible = (): boolean => {
    return shouldShowSection('addressSummary') && !!depositAddresses?.data?.results?.length;
  };

  const isWithdrawalAddressesVisible = (): boolean => {
    return shouldShowSection('addressSummary') && !!withdrawalAddresses?.data?.results?.length;
  };

  const isUserAddressesVisible = (): boolean => {
    return shouldShowSection('addressSummary') && !!userAddresses?.data?.results?.length;
  };

  const isDepositsVisible = (): boolean => {
    return shouldShowSection('deposits') && !!depositsData?.data?.results?.length;
  };

  const isWithdrawalsVisible = (): boolean => {
    return shouldShowSection('withdrawals') && !!withdrawalsData?.data?.results?.length;
  };

  let part = 1;

  const CustomerFooter: FC = () => {
    return <div className='text-3xs text-gray-500'>Customer Report: {customerData?.data?.customer_id}</div>;
  };

  return (
    <div>
      {shouldShowSection('customerDetails') && customerData?.data && (
        <Page footer={<CustomerFooter />}>
          <CustomerDetailsReport
            totalTransactions={depositsData?.data?.count + withdrawalsData?.data?.count}
            customerData={customerData?.data}
            part={part++}
          />
        </Page>
      )}
      {isAddressSummaryVisible() && (
        <Page footer={<CustomerFooter />}>
          <CustomerDigitalAssetReport tokenSummary={tokenSummary?.data?.results} part={part++} />
        </Page>
      )}
      {isDepositAddressesVisible() && (
        <Page footer={<CustomerFooter />}>
          <CustomerAddressesReport
            customerAddresses={depositAddresses?.data?.results}
            type='deposit'
            part={part++}
          />
        </Page>
      )}
      {isWithdrawalAddressesVisible() && (
        <Page footer={<CustomerFooter />}>
          <CustomerAddressesReport
            customerAddresses={withdrawalAddresses?.data?.results}
            type='withdrawal'
            part={part++}
          />
        </Page>
      )}
      {isUserAddressesVisible() && (
        <Page footer={<CustomerFooter />}>
          <CustomerAddressesReport
            customerAddresses={userAddresses?.data?.results}
            type='user'
            part={part++}
          />
        </Page>
      )}
      {isDepositsVisible() && (
        <Page footer={<CustomerFooter />}>
          <CustomerDepositWithdrawalReport
            transactions={depositsData?.data?.results}
            type='deposit'
            part={part++}
          />
        </Page>
      )}
      {isWithdrawalsVisible() && (
        <Page footer={<CustomerFooter />}>
          <CustomerDepositWithdrawalReport
            transactions={withdrawalsData?.data?.results}
            type='withdrawal'
            part={part++}
          />
        </Page>
      )}
      {isFlowAnalysisVisible() && (
        <Page footer={<CustomerFooter />}>
          <FlowAnalysis
            inputs={incomingValuesCustomerData?.data?.results}
            outputs={outgoingValuesCustomerData?.data?.results}
            part={part++}
          />
        </Page>
      )}
      {isAssociatedEntitiesVisible() && (
        <Page footer={<CustomerFooter />}>
          <CustomerAssociatedEntitiesReport
            deposits={incomingValuesCustomerData?.data?.results}
            withdrawals={outgoingValuesCustomerData?.data?.results}
            part={part++}
          />
        </Page>
      )}
      {openAlerts?.length > 0 && (
        <Page footer={<CustomerFooter />}>
          <AlertsReport
            alerts={openAlerts}
            type='customer'
            title='Open Alerts'
            identifier={customerId}
            part={part++}
          />
        </Page>
      )}
      {resolvedAlerts?.length > 0 && (
        <Page footer={<CustomerFooter />}>
          <AlertsReport
            type='customer'
            alerts={resolvedAlerts}
            title='Resolved Alerts'
            identifier={customerId}
            part={part++}
          />
        </Page>
      )}
      {comments?.length > 0 && (
        <Page footer={<CustomerFooter />}>
          <CommentsReport comments={comments} type='customer' identifier={customerId} part={part++} />
        </Page>
      )}
      {classifiers?.data.results && (
        <Page footer={<CustomerFooter />}>
          <ClassifiersReport type='address' classifiers={classifiers?.data.results} />
        </Page>
      )}
    </div>
  );
};

export default CustomerReport;
